import React from 'react';
import {Link} from 'gatsby';
import slack from '../../images/integrations/hexomatic-integrations-icons/slack.svg';
import telegram from '../../images/integrations/hexomatic-integrations-icons/telegram.svg';
import google from '../../images/integrations/hexomatic-integrations-icons/google.svg';
import googleSheets from '../../images/integrations/hexomatic-integrations-icons/google-sheets-export.svg';
import zapier from '../../images/integrations/hexomatic-integrations-icons/zapier.svg';
import pabbly from '../../images/integrations/hexomatic-integrations-icons/pabbly-connect.svg';
import webhook from '../../images/integrations/hexomatic-integrations-icons/webhook.svg';
import discord from '../../images/integrations/hexomatic-integrations-icons/discord.svg';
import teams from '../../images/integrations/hexomatic-integrations-icons/ms-teams.svg';
import api from '../../images/integrations/hexomatic-integrations-icons/api.svg';
import './styles.scss';

const PageIntegrations = () => {
  const points = [
    {
      //name: 'Telegram',
      logo: telegram,
      linkTo: '/telegrambot',
    },
    {
      //  name: 'Slack',
      logo: slack,
      linkTo: '/slackbot',
    },
    {
      //name: 'Gmail',
      logo: google,
    },
    {
      //name: 'Google Sheets',
      logo: googleSheets,
    },
    {
      //name: 'Zapier',
      logo: zapier,
      linkTo: '/zapier',
    },
    {
      //name: 'Pabbly Connect',
      logo: pabbly,
      linkTo: "/pabbly-connect"
    },
    {
      //name: 'Webhook',
      logo: webhook,
      linkTo: '/webhook',
    },
    {
      //name: 'Discord',
      logo: discord,
      linkTo: '/discord',
    },
    {
      //name: 'API',
      logo: api,
      linkTo: '/api-documentation',
    },
    {
      //name: 'MS Teams',
      logo: teams,
      linkTo: '/microsoft-teams',
    },
  ];

  return (
    <div className="pageIntegrations text-center mb-30" style={{width: '100%'}}>
      <div className="integration-mobile row m-0">
        {points.map(item => {
          return (
            <div className="integration-item">
              <div className="promo_item">
                {item.linkTo ? (
                  <Link to={item.linkTo || ''}>
                    <img src={item.logo} alt={''} width={44} height={44} />
                    {/* <p>{item.name}</p> */}
                  </Link>
                ) : (
                  <>
                    <img src={item.logo} alt={''} width={44} height={44} />
                    {/* <p>{item.name}</p> */}
                  </>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PageIntegrations;
