import React from 'react';
import Section from '../../components/section';
import { PageHero } from '../../components/page-hero';
import Layout from '../../components/layout/layout';
import PageIntegrations from '../../components/PageIntegration';
import './styles.scss';

const SoftwareIntegrations = () => {
  return (
    <Layout>
      <PageHero
        title="Software Integrations"
        subtitle="List of 3rd party integrations with Hexowatch"
      />
      <Section>
        <PageIntegrations />
      </Section>
    </Layout>
  );
};

export default SoftwareIntegrations;
